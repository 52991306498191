import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      {/* <p>Renaissance man. Polymath. Generalist. I have been fixated on these ideas for quite some time now. My interest in these ideas grew from the anxiety of having to choose what to do with my life.</p> */}

      <p> Coming soon! </p>
    </Layout>
  )
}

export default AboutPage